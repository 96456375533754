import React, { useState } from 'react';
import { Link, navigate } from 'gatsby';

import TransitInfo from './transit-info';
import formatRichText from '../format-rich-text';
import { getRandomTransitColor } from '../utils';
import { DealSavings } from './deal-savings';

export const Deal = ({ deal, hideToggle = false }) => {
  const {
    slug,
    title,
    transit,
    endDate,
    seasonalDeal,
    heroImage,
    categories,
    dealAmount,
    subcategories,
    dealDescription,
  } = deal;

  const [toggleLabel, setToggleLabel] = useState('Show More');
  const [detailsHidden, setDetailsHidden] = useState(true);
  const [randomColor] = useState(getRandomTransitColor(transit));
  const handleToggleDetails = () => {
    setToggleLabel(detailsHidden ? 'Show less' : 'Show more');
    setDetailsHidden(!detailsHidden);
  };

  return (
    <li
      className={`deal ${!detailsHidden && 'details-Open'}`}
      style={{ borderColor: `#6390BF` }}
    >
      <div className="deal__content">
        <a href={`/deals/${slug}`}>
          <div className="deal__header">
            <div className="deal__meta">
              {seasonalDeal && (
                <span className="deal__seasonal-tag"> seasonal deal </span>
              )}
              {categories.map(({ title }) => (
                <div key={title} className="deal__meta__item">
                  {title}
                </div>
              ))}
              {subcategories?.map(({ title }) => (
                <div key={title} className="deal__meta__item">
                  {title}
                </div>
              ))}
            </div>
            <h2 className="deal__title">{title}</h2>
          </div>

          <DealSavings
            endDate={endDate}
            dealAmount={dealAmount}
            detailsHidden={detailsHidden}
          />
        </a>
        {!hideToggle && detailsHidden && (
          <>
            <hr />
            <button
              className="deal__toggle"
              onClick={handleToggleDetails}
              type="button"
            >
              {toggleLabel}
            </button>
          </>
        )}
      </div>
      {heroImage && (
        <div
          tabIndex={0}
          role="button"
          aria-label="Navigate to deal"
          className="deal__photo"
          style={{
            backgroundImage: `url(${heroImage?.fluid?.src})`,
          }}
          onClick={() => navigate(`/deals/${slug}`)}
          onKeyDown={() => navigate(`/deals/${slug}`)}
        />
      )}
      {!detailsHidden && (
        <>
          <div className="deal__description">
            {formatRichText(dealDescription.json)}
          </div>
          <div className="deal__transit">
            <p className="deal__transit__label">Plan your trip</p>
            <TransitInfo {...transit} isDeal />
            {!hideToggle && (
              <div className="deal__links">
                <button
                  className="deal__toggle"
                  onClick={handleToggleDetails}
                  type="button"
                >
                  {toggleLabel}
                </button>
                <div
                  tabIndex={0}
                  role="button"
                  aria-label="Navigate to deal"
                  className="deal__toggle"
                  onClick={() => navigate(`/deals/${slug}`)}
                  onKeyDown={() => navigate(`/deals/${slug}`)}
                >
                  Full Page
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </li>
  );
};
