import React from 'react';
import { graphql } from 'gatsby';

import { Deal } from '../components/deal';
import Layout from '../components/layout';
import TransitInfo from '../components/transit-info';
import { DealSavings } from '../components/deal-savings';
import { PricingTable } from '../components/pricing-table';

import fixAssetUrl from '../fix-asset-url';
import formatRichText from '../format-rich-text';
import { useArticleProgress } from '../hooks/use-article-progress';
import useMobile from '../hooks/use-mobile';

import { getFirstTransitColor, getRandomHeroImageByCategory } from '../utils';

export default ({ data }) => {
  const { contentfulDeal, moreDeals, contentfulHomePage } = data;

  const isMobile = useMobile();

  useArticleProgress();

  const {
    slug,
    title,
    adUnits,
    endDate,
    pricing,
    transit,
    heroImage,
    categories,
    dealAmount,
    directions,
    dealDetails,
    photoCredit,
    pricingTable,
    shortEndDate,
    subcategories,
    dealDescription,
  } = contentfulDeal;

  const image = heroImage
    ? heroImage
    : getRandomHeroImageByCategory(categories, contentfulHomePage.categories);
  return (
    <Layout
      title={title}
      canonicalUrl={`https://away.mta.infodeals/${slug}`}
      // TODO: this can be MD, should be handled differently
      // description={deal.description?.childMarkdownRemark?.rawMarkdownBody}
      image={fixAssetUrl(image.fixed.src)}
      mainClass="article-page"
      currentSection="deals"
      backToLink="/deals"
      backToLabel="Back to Deals"
    >
      <article className={`deal-details ${adUnits?.length ? '' : 'no-border'}`}>
        <div className="article-header">
          <ul className="article__meta">
            {categories?.map(({ title }) => (
              <li key={title}>{title}</li>
            ))}
            {subcategories?.map(({ title }) => (
              <li key={title}>{title}</li>
            ))}
          </ul>
          <div className="article-header__title deal-title">
            <h1>{title || 'Untitled Deal'}</h1>
            <div
              className="article-progress"
              style={{ color: getFirstTransitColor(transit) }}
            />
          </div>
          <div>
            <DealSavings
              className={'deal__savings-page'}
              endDate={endDate}
              dealAmount={dealAmount}
              shortEndDate={shortEndDate}
            />
            <img
              className="article-header__image"
              alt={image.fixed.title}
              src={fixAssetUrl(image.fixed.src)}
            />
          </div>
          {photoCredit && (
            <div className="deal-photo-credit">
              <figcaption>{photoCredit}</figcaption>
            </div>
          )}
        </div>
        <div className="article-content">
          <div className="article-content-inner">
            {dealDescription && formatRichText(dealDescription.json)}
            {dealDetails && formatRichText(dealDetails.json)}
            {pricing && formatRichText(pricing.json)}
            {pricingTable && <PricingTable pricingTable={pricingTable} />}
            {directions && formatRichText(directions.json)}
          </div>
          {transit && <TransitInfo {...transit} />}
        </div>
        {moreDeals.nodes.length > 0 && (
          <div className="related-deals">
            <h2>More Deals</h2>
            <ul className="deal-list">
              {moreDeals.nodes.map(deal => {
                const newDeal = { ...deal };
                if (!deal.heroImage) {
                  newDeal.heroImage = getRandomHeroImageByCategory(
                    deal.categories,
                    contentfulHomePage.categories
                  );
                }

                return (
                  <Deal key={deal.id} deal={newDeal} hideToggle={isMobile} />
                );
              })}
            </ul>
          </div>
        )}
      </article>
      <aside className="sidebar">
        <div className="sidebar-inner">
          {(adUnits || []).map(adUnit => (
            <div key={adUnit.id}>
              <a href={adUnit.link} target="_blank" rel="noreferrer">
                <img
                  src={adUnit.asset.file.url}
                  width={adUnit.asset.file.details.image.width}
                  height={adUnit.asset.file.details.image.height}
                  alt={adUnit.asset.title}
                />
              </a>
            </div>
          ))}
        </div>
      </aside>
    </Layout>
  );
};

export const pageQuery = graphql`
  query DealBySlug($slug: String!) {
    contentfulDeal(slug: { eq: $slug }) {
      ...DealFragment
      heroImage {
        title
        fixed(width: 1180) {
          src
        }
      }
    }
    moreDeals: allContentfulDeal(
      limit: 2
      filter: { slug: { ne: $slug }, holdForApproval: { in: [null, false] } }
    ) {
      nodes {
        ...DealListFragment
      }
    }
    contentfulHomePage {
      categories {
        slug
        featuredImage {
          fluid(maxWidth: 1280, maxHeight: 717, resizingBehavior: FILL) {
            src
          }
          fixed(width: 1180) {
            src
          }
        }
      }
    }
  }
`;
