import React from 'react';
import calendarIcon from '/static/calendar.png';
import { calculateDaysLeft } from '../utils';

export const DealSavings = ({
  className,
  dealAmount,
  endDate,
  shortEndDate,
  detailsHidden,
}) => {
  const daysLeft = calculateDaysLeft(endDate);
  const daysStr = daysLeft === 1 ? 'day' : 'days';
  const expiresToday = daysLeft === 0;
  const expired = daysLeft < 0;
  const msg = expired
    ? 'Expired '
    : expiresToday
    ? 'Expires today '
    : `${daysLeft} ${daysStr} left`;
  return (
    <div className={`deal__savings ${detailsHidden ? 'closed' : className}`}>
      <div className="deal__savings__amount">{dealAmount}</div>
      <div className="deal__savings__days">
        <img src={calendarIcon} alt="calendar icon" />
        {`${msg} ${shortEndDate ? `- Offer ends (${shortEndDate})` : ''}`}
      </div>
    </div>
  );
};
