import { useEffect, useState } from 'react';

export const useMobile = () => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (window !== undefined && window.innerWidth <= 500) {
      setIsMobile(window.innerWidth <= 500);
    }
  }, []);
  return isMobile;
};

export default useMobile;
